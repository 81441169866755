<template>
  <div class="pageWrapper">
    <div class="titleBar">
      工单基本信息
    </div>
    <div class="meetingDetailsBox">
      <div class="singleInfo">
        <span>会议主题：</span>
        <span>{{ meetingDetails.theme }}</span>
      </div>
      <div class="singleInfo">
        <span>会议时间：</span>
        <span v-if="meetingDetails.startTime">{{
          meetingDetails.startTime.slice(0, 16)
        }}</span>
        <span>~</span>
        <span v-if="meetingDetails.endTime">{{
          meetingDetails.endTime.slice(0, 16)
        }}</span>
      </div>
      <div class="singleInfo">
        <span>会议等级：</span>
        <span>{{ meetingDetails.level | levelStatus }}</span>
      </div>
      <div class="singleInfo">
        <span>会议室：</span>
        <span>{{ meetingDetails.roomName }}</span>
      </div>
      <div class="singleInfo">
        <span>会议类型：</span>
        <span>{{ meetingDetails.type | typeStatus }}</span>
      </div>
      <div class="singleInfo">
        <span>运维工程师：</span>
        <span>{{ meetingDetails.operationNames }}</span>
      </div>
      <div class="singleInfo">
        <span>主办方：</span>
        <span>{{ meetingDetails.sponsor }}</span>
      </div>
      <div class="singleInfo">
        <span>联系人：</span>
        <span>{{ meetingDetails.contacts }}</span>
      </div>
      <div class="singleInfo">
        <span>联系电话：</span>
        <span>{{ meetingDetails.contactPhone }}</span>
      </div>
      <div class="singleInfo">
        <span>是否发信息给主办方：</span>
        <span>{{ meetingDetails.sms ? "是" : "否" }}</span>
      </div>
      <div class="singleInfo">
        <span>重要领导：</span>
        <span>{{ meetingDetails.leader }}</span>
      </div>
      <div class="singleInfo">
        <span>工作描述：</span>
        <div>{{ meetingDetails.desc }}</div>
      </div>
    </div>
    <div class="titleBar" v-if="meetingDetails.workorderScheduleVoList">
      工作进度
    </div>
    <div
      class="meetingDetailsBox"
      v-if="meetingDetails.workorderScheduleVoList"
    >
      <div
        class="singleNode"
        v-for="item in meetingDetails.workorderScheduleVoList"
        :key="item.scheduleId"
      >
        <div class="singleStatus">
          <span>{{ item.name + "：" }}</span>
          <span>{{ item.finish ? "已完成" : "待完成" }}</span>
        </div>
        <van-button
          v-if="item.finish"
          @click="viewNode(item)"
          class="miniButton"
          plain
          type="primary"
          size="mini"
          >查看</van-button
        >
      </div>
    </div>
    <!-- 取消工单原因 -->
    <div
      class="titleBar"
      v-if="
        meetingDetails.workorderCancelList &&
          meetingDetails.workorderCancelList.length
      "
    >
      取消工单原因
    </div>
    <div
      class="meetingDetailsBox"
      v-if="
        meetingDetails.workorderCancelList &&
          meetingDetails.workorderCancelList.length
      "
    >
      <div
        v-for="item in meetingDetails.workorderCancelList"
        :key="item.cancelId"
      >
        <div class="singleInfo">
          <span>取消人：</span>
          <span>{{ item.cancelPeople }}</span>
        </div>
        <div class="singleInfo">
          <span>取消时间：</span>
          <span>{{ item.cancelTime }}</span>
        </div>
        <div class="singleInfo">
          <span>取消原因：</span>
          <div>{{ item.cancelReason }}</div>
        </div>
      </div>
    </div>
    <div class="bottomButtonWrapper">
      <van-button
        v-if="
          isAdmin &&
            (meetingDetails.status === 1 || meetingDetails.status === 2)
        "
        @click="handleCancelWorkOrder"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >取消工单</van-button
      >
      <van-button
        v-if="
          isAdmin &&
            (meetingDetails.status === 1 || meetingDetails.status === 2)
        "
        @click="handleChangeResponsor"
        class="bottomButton"
        type="info"
        size="small"
        style="font-size:.8rem;font-weight:500;background-color:#2bb7b3;border: 1px solid #2bb7b3;border-radius:5px"
        >转派</van-button
      >
      <van-button
        v-if="!isAdmin && meetingDetails.status === 1 && operationAuth"
        @click="handleStartWorkOrder"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >开始工作</van-button
      >
      <van-button
        v-if="!isAdmin && meetingDetails.status === 2 && operationAuth"
        @click="handleContinueWorkOrder"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >继续工作</van-button
      >

      <van-button
        v-if="meetingDetails.status === 0 && isAdmin"
        @click="handleEditWorkOrder"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >修改</van-button
      >

      <van-button
        v-if="changeMeetingRoomAuthority"
        @click="handleEditMeetingRoom"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >会议变更</van-button
      >
    </div>

    <van-dialog
      v-model="cancelMeetingVisible"
      title="取消工单"
      show-cancel-button
      @confirm="handleConfirmCancel"
      @cancel="handleRejectCancel"
    >
      <van-form ref="cancelReasonForm">
        <van-field
          v-model="cancelingReason"
          rows="4"
          autosize
          type="textarea"
          placeholder="请输入取消工单原因"
          :rules="[{ required: true, message: '请输入取消工单原因' }]"
        />
      </van-form>
    </van-dialog>

    <van-dialog
      v-model="recommitMeetingVisible"
      title="转派工单"
      show-cancel-button
      @confirm="handleConfirmRecommit"
      @cancel="handleRejectRecommit"
    >
      <van-form ref="cancelRecommitForm">
        <van-field
          readonly
          clickable
          required
          name="meetingType"
          :value="computedOperators"
          :rules="[{ required: true, message: '请选择运维工程师' }]"
          label="运维工程师"
          placeholder="点击运维工程师"
          @click="openOperatorsPopup"
        />
      </van-form>
    </van-dialog>
    <van-popup
      v-model="meetingOperatorsVisible"
      position="bottom"
      closeable
      @close="handleCloseOperatorsPopup"
    >
      <van-tree-select
        style="margin-top:30px"
        :items="operatorsList"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
      />
    </van-popup>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getOperators } from "@/api/user";
import {
  getMeetingDetails,
  cancelMeeting,
  recommitMeeting,
  atartMeeting,
} from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      meetingDetails: {},
      cancelMeetingVisible: false,
      cancelingReason: "",
      recommitMeetingVisible: false,
      sids: [],
      computedOperators: "",
      meetingOperatorsVisible: false,
      activeIds: [],
      operatorsList: [],
      activeIndex: 0,
      operationAuth: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    isAdmin() {
      return this.$store.state.user.IsAdmin + "" === "true";
    },
    changeMeetingRoomAuthority() {
      let status = this.meetingDetails.status,
        authority = status == 1 || status == 2;
      return authority;
    },
  },
  //监控data中的数据变化
  watch: {},
  filters: {
    levelStatus(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "VIP会议",
        2: "普通会议",
        3: "重要会议",
        4: "会议支持",
      };
      return `${levelObj[level]}`;
    },
    typeStatus(type) {
      if (type == null || type == undefined) return "";
      let typeObj = {
        1: "常规会议",
        2: "视频会议",
        3: "直播会议",
      };
      return `${typeObj[type]}`;
    },
  },
  //方法集合
  methods: {
    viewNode(item) {
      console.log(item);
      this.$store.commit("SetIsEditing", false);
      this.$store.commit("SetCurrentScheduleId", item.scheduleId);

      if (item.name === "会前") {
        this.$store.commit("SetNavBarTitle", "会前工作");
        this.$router.push("/meetingPrepare");
      } else if (item.name === "会中") {
        this.$store.commit("SetNavBarTitle", "会中工作");
        this.$router.push("/inprogressSchedule");
      } else if (item.name === "会后") {
        this.$store.commit("SetNavBarTitle", "会后工作");
        this.$router.push("/afterMeetingProblem");
      } else if (item.name === "会议支持") {
        this.$store.commit("SetNavBarTitle", "会议支持");
        this.$router.push("/meetingSupport");
      }
    },
    handleStartWorkOrder() {
      if (!this.operationAuth) {
        this.$notify({ type: "warning", message: "非运维人员不能进行此操作" });
        return;
      }

      this.$store.commit("SetIsEditing", true);
      atartMeeting({ workorderId: this.meetingDetails.workorderId })
        .then((res) => {
          if (res.code === 10000) {
            this.$notify({ type: "success", message: "操作成功" });
            if (this.currentMeeting.level === 4) {
              this.$store.commit("SetNavBarTitle", "会议支持");
              this.$router.push("/meetingSupport");
            } else {
              this.$store.commit("SetNavBarTitle", "会前工作");
              this.$router.push("/meetingPrepare");
            }
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.message });
        });
    },
    handleEditWorkOrder() {
      this.$store.commit("SetIsEditing", true);
      this.$router.push("/addMeeting");
      this.$store.commit("SetNavBarTitle", "修改工单");
    },
    handleEditMeetingRoom() {
      this.$store.commit("SetIsEditing", true);
      this.$router.push("/changeMeetingRoom");
      this.$store.commit("SetNavBarTitle", "会议变更");
    },
    handleContinueWorkOrder() {
      // 判断工单进度跳转页面
      if (!this.operationAuth) {
        this.$notify({ type: "warning", message: "非运维人员不能进行此操作" });
        return;
      }
      this.$store.commit("SetIsEditing", true);

      if (this.meetingDetails.level !== 4) {
        if (this.meetingDetails.schedule === 0) {
          this.$store.commit("SetNavBarTitle", "会前工作");
          this.$router.push("/meetingPrepare");
        } else if (this.meetingDetails.schedule === 1) {
          this.$store.commit("SetNavBarTitle", "会中工作");
          this.$router.push("/inprogressSchedule");
        } else if (this.meetingDetails.schedule === 2) {
          this.$store.commit("SetNavBarTitle", "会后工作");
          this.$router.push("/afterMeetingProblem");
        }
      } else {
        this.$store.commit("SetNavBarTitle", "会议支持");
        this.$router.push("/meetingSupport");
      }
    },
    getAllOperators() {
      getOperators().then((res) => {
        if (res.code === 10000) {
          // res.data.records
          let childrenList = res.data.map((item) => {
            let obj = {
              id: item.sid,
              text: item.name,
            };
            return obj;
          });
          this.operatorsList = [{ text: "运维工程师", children: childrenList }];
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    openOperatorsPopup() {
      this.meetingOperatorsVisible = true;
    },
    handleCloseOperatorsPopup() {
      this.sids = this.activeIds;

      if (this.activeIds.length) {
        let operatorsNames = this.activeIds.map((item) => {
          let name = this.operatorsList[0].children.filter((subItem) => {
            return subItem.id === item;
          })[0].text;
          return name;
        });
        this.computedOperators = operatorsNames.join("、");
      } else {
        this.computedOperators = "";
      }
    },
    handleRejectRecommit() {
      this.recommitMeetingVisible = false;
    },
    handleConfirmRecommit() {
      this.$refs.cancelRecommitForm
        .validate()
        .then(() => {
          let param = {
            sids: this.sids,
            workorderId: this.meetingDetails.workorderId,
          };
          recommitMeeting(param)
            .then((res) => {
              if (res.code === 10000) {
                this.cancelMeetingVisible = false;
                this.$notify({ type: "success", message: "操作成功" });
                this.$store.commit("SetNavBarTitle", "会议工单");
                this.$router.push("/meetingList");
              } else {
                this.$notify({ type: "warning", message: res.message });
              }
            })
            .catch((error) => {
              this.$notify({ type: "warning", message: error.message });
            });
        })
        .catch(() => {
          this.recommitMeetingVisible = true;
        });
    },
    handleRejectCancel() {
      this.cancelMeetingVisible = false;
    },
    handleConfirmCancel() {
      this.$refs.cancelReasonForm
        .validate()
        .then(() => {
          let param = {
            reason: this.cancelingReason,
            workorderId: this.meetingDetails.workorderId,
          };
          cancelMeeting(param)
            .then((res) => {
              if (res.code === 10000) {
                this.cancelMeetingVisible = false;
                this.$notify({ type: "success", message: "操作成功" });
                this.$store.commit("SetNavBarTitle", "会议工单");
                this.$router.push("/meetingList");
              } else {
                this.$notify({ type: "warning", message: res.message });
              }
            })
            .catch((error) => {
              this.$notify({ type: "warning", message: error.message });
            });
        })
        .catch(() => {
          this.cancelMeetingVisible = true;
        });
    },
    handleCancelWorkOrder() {
      this.cancelMeetingVisible = true;
    },
    handleChangeResponsor() {
      this.recommitMeetingVisible = true;
    },
    getCurrentMeetingDetails() {
      let param = {
        workorderId: this.currentMeeting.workorderId,
      };
      getMeetingDetails(param).then((res) => {
        if (res.code === 10000) {
          this.meetingDetails = res.data;
          if (this.meetingDetails.operationSids) {
            let currentSid = sessionStorage.getItem("sid");
            let operationAuth = this.meetingDetails.operationSids.some(
              (item) => {
                return item === currentSid + "";
              }
            );
            this.operationAuth = operationAuth;
          } else {
            this.operationAuth = false;
          }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCurrentMeetingDetails();
    if (this.isAdmin) {
      this.getAllOperators();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
};
</script>
<style>
/* @import url(); 引入公共css类*/
.meetingDetailsBox {
  background-color: #fff;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}
.singleInfo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: center;
}
.singleInfo span,
div {
  font-size: 0.6rem;
  text-align: left;
  word-break: break-word;
}
.singleNode {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}
.singleNode .miniButton .van-button__text {
  font-size: 0.3rem !important;
}
.miniButton {
  padding: 0 14px !important;
  height: 19px;
  border-radius: 3px;
  border: 1px solid #4bc2be !important;
  color: #4bc2be !important;
}
</style>
